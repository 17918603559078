<template>
  <b-card
    class="card-app-design rooms-container"
    title="Manage Stockin"
  >
    <b-row>
      <b-col sm="12">
        <div class="custom-search d-md-flex justify-content-between mb-3">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
          <b-button
            variant="outline-primary"
            size="md"
            @click="handleOpenRegisterModal()"
          >
            Register
          </b-button>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :pagination-options="{
            enabled: false,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="handleOpenViewStockinModal(props.row)">
                    <feather-icon
                      icon="EyeIcon"
                      class="mr-50"
                    />
                    <span>View</span>
                  </b-dropdown-item>
                  <!-- <b-dropdown-item @click="handleOpenEditStockinModal(props.row)">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item> -->
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">

              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <b-modal
          v-model="openRegisterLivestockDetailsModal"
          title="Stockin Details"
          size="lg"
          ok-only
        >
          <b-row>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Item:</span><span class="text-info ml-1">{{ stockinFormData.item_name }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Stockin Quantity:</span><span class="text-info ml-1">{{ formatNumber(stockinFormData.quantity) }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Unit:</span><span class="text-info ml-1">{{ stockinFormData.item_unit_name }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Cost Price:</span><span class="text-info ml-1">{{ formatNumber(stockinFormData.total_cost)+'Ugx' }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Stockin Date:</span><span class="text-info ml-1">{{ stockinFormData.stock_in_date }}</span></p>
            </b-col>
            <b-col
              sm="12"
            >
              <p><span>Additional Notes:</span><span class="text-info ml-1">{{ stockinFormData.remarks }}</span></p>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          v-model="openRegisterStockinModal"
          title="Register Stockin"
          size="lg"
          ok-title="Submit"
          @ok.prevent="validationForm"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group label="Stockin Item">
                    <validation-provider
                      #default="{ errors }"
                      name="Item"
                      rules="required"
                    >
                      <b-form-select
                        v-model="stockinItem"
                        :options="stockinItemOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group label="Quantity">
                    <validation-provider
                      #default="{ errors }"
                      name="Quantity"
                      rules="required"
                    >
                      <b-form-input
                        v-model="stockinFormData.quantity"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Quantity"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group label="Unit">
                    <validation-provider
                      #default="{ errors }"
                      name="Unit"
                      rules="required"
                    >
                      <b-form-select
                        v-model="itemUnit"
                        :options="itemUnitOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group label="Total price">
                    <validation-provider
                      #default="{ errors }"
                      name="Cost Price"
                      rules="required"
                    >
                      <b-form-input
                        v-model="stockinFormData.total_cost"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Total Price(UGX)"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group label="Stockin Date">
                    <validation-provider
                      #default="{ errors }"
                      name="Stockin Date"
                      rules="required"
                    >
                      <b-form-datepicker
                        v-model="stockinFormData.stock_in_date"
                        placeholder="Choose Stockin Date"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group label="Additional Notes">
                    <b-form-textarea
                      v-model="stockinFormData.remarks"
                      placeholder="Enter Additional Notes"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge,
  BFormDatepicker, BFormSelect, BFormTextarea, BModal,
  BForm, BPagination, BFormGroup, BFormInput, BDropdownItem, BDropdown,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import { supabase } from '@/libs/supabaseClient'
// eslint-disable-next-line import/no-cycle
import { formatNumber } from '@core/utils/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BModal,
    BForm,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BFormTextarea,
  },
  data() {
    return {
      log: [],
      pageLength: 3,
      configLiveStockData: [],
      configUnitData: [],
      inventoryDataRes: [],
      openRegisterStockinModal: false,
      openRegisterLivestockDetailsModal: false,
      openManageLivestockModal: false,
      isUpdate: false,
      selectedStatus: null,
      stockinItem: null,
      approvedBy: null,
      itemUnit: null,
      recievedBy: null,
      dir: false,
      required,
      formatNumber,
      email,
      stockinFormData: {
        item_id: 0,
        item_name: '',
        item_unit_id: 0,
        item_unit_name: '',
        quantity: 0,
        total_cost: 0,
        stock_in_date: '',
        stock_in_time: 0,
        remarks: '',
        type: '',
      },
      pages: ['3', '5', '10'],
      approvedByOptions: [
        { value: null, text: 'Select Staff', disabled: true },
        { value: 'Smith', text: 'John' },
      ],
      recievedByOptions: [
        { value: null, text: 'Select staff', disabled: true },
        { value: 'John Doe', text: 'John Doe' },
      ],
      itemUnitOptions: [
        { value: null, text: 'Select Unit', disabled: true },
      ],
      stockinItemOptions: [
        { value: null, text: 'Select Item', disabled: true },
      ],
      livestockStatusOptions: [
        { value: null, text: 'Select Status', disabled: true },
        { value: 'Died', text: 'Died' },
        { value: 'Slaughtered for domestic consumption', text: 'Slaughtered for domestic consumption' },
        { value: 'Donated', text: 'Donated' },
      ],
      columns: [
        {
          label: 'Date',
          field: 'stock_in_date',
        },
        {
          label: 'Name',
          field: 'item_name',
        },
        {
          label: 'Quantity',
          field: 'quantity',
        },
        {
          label: 'Unit',
          field: 'item_unit_name',
        },
        {
          label: 'Amount',
          field(item) {
            return formatNumber(item.total_cost)
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.handleGetConfigLiveStock()
    this.handleGetStockinData()
  },
  methods: {
    resetStockinFormData() {
      this.stockinItem = null
      this.itemUnit = null
      this.stockinFormData = {
        item_id: 0,
        item_name: '',
        item_unit_id: 0,
        item_unit_name: '',
        quantity: 0,
        total_cost: 0,
        stock_in_date: '',
        stock_in_time: 0,
        remarks: '',
        type: '',
      }
    },
    handleOpenRegisterModal() {
      this.resetStockinFormData()
      this.openRegisterStockinModal = true
    },
    handleOpenViewStockinModal(item) {
      this.resetStockinFormData()
      this.openRegisterLivestockDetailsModal = true
      this.stockinFormData = item
    },
    handleOpenEditStockinModal(item) {
      this.openRegisterStockinModal = true
      this.stockinFormData = item
    },
    async handleUpdateStockin() {
      this.$Progress.start()
      const selectedLivestock = this.handleGetConfigLivestockData()
      this.stockinFormData.gender = this.livestockGender
      this.stockinFormData.live_stock_id = this.livestockItem
      this.stockinFormData.name = selectedLivestock[0].name
      this.stockinFormData.category = selectedLivestock[0].category
      this.stockinFormData.type = 'local'
      this.stockinFormData.acquistion_type = this.stockinChannel
      // eslint-disable-next-line radix
      this.stockinFormData.quantity = parseInt(this.stockinFormData.quantity)
      this.stockinFormData.farm_id = 1
      const stockinTimesatmp = new Date(this.stockinFormData.stock_in_date).getTime()
      this.stockinFormData.stock_in_date_timestamp = stockinTimesatmp / 1000
      try {
        const { error } = await supabase
          .from('live_stock_and_poultry')
          .update({
            name: this.stockinFormData.name,
            category: this.stockinFormData.category,
            type: this.stockinFormData.type,
            stock_in_date: this.stockinFormData.stock_in_date,
            stock_in_date_timestamp: this.stockinFormData.stock_in_date_timestamp,
            gender: this.stockinFormData.gender,
            acquistion_type: this.stockinFormData.acquistion_type,
            description: this.stockinFormData.description,
            live_stock_id: this.stockinFormData.live_stock_id,
            natal_date: this.stockinFormData.natal_date,
            farm_id: this.stockinFormData.farm_id,
          })
          .eq('id', this.stockinFormData.id)
          .select()
        if (error) {
          this.$Progress.fail()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Update Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          this.$Progress.finish()
          this.openRegisterStockinModal = false
          this.isUpdate = false

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Update Successful',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Data posted successfully',
            },
          })
          this.handleGetLiveStock()
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error Occurred',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.resetStockinFormData()
      }
    },
    handleGetConfigLivestockData() {
      const selectedItem = this.configLiveStockData.filter(item => item.id === this.stockinItem)
      return selectedItem
    },
    handleGetConfigUnitData() {
      const selectedItem = this.configUnitData.filter(item => item.id === this.itemUnit)
      return selectedItem
    },
    handleGetInventoryItem() {
      const selectedItem = this.inventoryDataRes.filter(item => item.item_id === this.stockinItem)
      return selectedItem
    },
    async handleRegisterStockin() {
      this.$Progress.start()
      const selectedLivestock = this.handleGetConfigLivestockData()
      const selectedUnitData = this.handleGetConfigUnitData()
      const filteredInventoryItem = this.handleGetInventoryItem()
      this.stockinFormData.item_id = this.stockinItem
      this.stockinFormData.item_name = selectedLivestock[0].name
      this.stockinFormData.type = 'Local'
      this.stockinFormData.item_unit_id = selectedUnitData[0].id
      this.stockinFormData.item_unit_name = selectedUnitData[0].name
      // eslint-disable-next-line radix
      this.stockinFormData.quantity = parseInt(this.stockinFormData.quantity)
      const stockinTimesatmp = new Date(this.stockinFormData.stock_in_date).getTime()
      this.stockinFormData.stock_in_time = stockinTimesatmp / 1000
      console.log('::deubug this.stockinFormData:', this.stockinFormData)
      try {
        const { error } = await supabase
          .from('stock_in')
          .insert([
            this.stockinFormData,
          ])
          .select()
        const { error: updateError } = await supabase
          .from('inventory')
          .update({
            quantity: this.stockinFormData.quantity + filteredInventoryItem[0].quantity,
          })
          .eq('item_id', this.stockinFormData.item_id)
          .select()
        if (error || updateError) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registration Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registration Successful',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Data posted successfully',
            },
          })
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Registration Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.openRegisterStockinModal = false
        this.resetStockinFormData()
        this.handleGetStockinData()
        this.$Progress.finish()
      }
    },
    async handleGetConfigLiveStock() {
      this.$Progress.start()
      try {
        const { data: response, error } = await supabase
          .from('config_stock_category')
          .select(`
            *
          `).order('name', { ascending: true })
        const { data: unitResponse, unitError } = await supabase
          .from('config_stock_item_unit')
          .select('*').order('name', { ascending: true })
        console.log('::debug response:', response)
        if (error || unitError) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Fetch Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          if (response.length > 0) {
            this.configLiveStockData = response
            response.map(item => this.stockinItemOptions.push(
              {
                value: item.id,
                text: item.name,
              },
            ))
          }
          if (unitResponse.length > 0) {
            this.configUnitData = unitResponse
            unitResponse.map(item => this.itemUnitOptions.push(
              {
                value: item.id,
                text: item.name,
              },
            ))
            console.log('::debug this.itemUnitOptions:', this.itemUnitOptions)
          }
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Fetch Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.$Progress.finish()
      }
    },
    async handleGetStockinData() {
      this.$Progress.start()
      try {
        const { data: response, error } = await supabase
          .from('stock_in')
          .select(`
            *
          `).order('stock_in_time', { ascending: false })
        const { data: InventoryResponse, invetoryError } = await supabase
          .from('inventory')
          .select(`
            *
          `)
        if (error || invetoryError) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Fetch Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          this.inventoryDataRes = InventoryResponse
          if (response.length > 0) {
            console.log('::debug produce response:', response)
            this.rows = []
            this.rows = response
          }
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Fetch Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.$Progress.finish()
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.isUpdate) {
            this.handleUpdateStockin()
          } else {
            this.handleRegisterStockin()
          }
        }
      })
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
